import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Accordion from 'react-bootstrap/Accordion'

const PricePage = () => {
  return (
    <Layout activePage="price">
      <Seo title="Адвокатский кабинет Храпова Юрия Николаевича"/>
      <h2 className="main-title">
        Стоимость помощи адвоката
      </h2>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Для граждан
          </Accordion.Header>
          <Accordion.Body>
            <table className="table-main" border="1" width="100%" cellSpacing="0" cellPadding="5">
              <tbody>
                <tr className="table-cell">
                  <td className="table-cell" valign="top">
                    <p className="p-style"><strong className="no-margin">Виды оказываемой юридической помощи</strong></p>
                  </td>
                  <td className="table-cell" valign="top" width="150">
                    <p className="p-style"><strong className="no-margin">Размер оплаты</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2"><strong className="no-margin">Юридическая справка&nbsp;</strong>(не более одного вопроса без консультирования)</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style"><strong className="no-margin">500 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2"><strong className="no-margin">Устная консультация</strong></p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 1 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Сложная устная консультация, требующая изучения документов или поиска применимых норм права и путей решения</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 1 500 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Устное консультирование по вопросам уголовного, уголовно – процессуального и гражданского процессуального права, по вопросам недвижимости, споров с органами власти, разрешение дел о разделе имущества, наследстве.</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 2 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2"><strong className="no-margin">Письменная консультация</strong>&nbsp;(с ссылками на нормы права или примерами из судебной практики)</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style"><strong className="no-margin">от 2 500 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Участие в переговорах (сопровождение адвоката)</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">по договору</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">&nbsp;</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style2">&nbsp;</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Составление небольшого по объёму документа</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 1 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Составление претензии, отзыва</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 2 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Составление правового заключения</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 3 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2"><strong className="no-margin">Составление проекта&nbsp;</strong>договора, сделки.</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style"><strong className="no-margin">от 5 000 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Составление отзыва в суд, административный орган или орган власти</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 5 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2"><strong className="no-margin">Составление искового заявления</strong>, жалобы в суд, административный орган или орган власти<em className="fw-bold no-margin">(включая консультирование и изучение документов).</em></p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <div className="no-margin">
                      <p className="p-style"><span className="text-decoration-underline no-margin"><strong className="no-margin">простое от 5&nbsp;000 руб.</strong></span></p>
                    </div>
                    <p className="p-style">сложные<br className="no-margin"/>от 10 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2">Однократное участие в судебном заседании, заседании административном органе.</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style">от 10 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2"><strong className="no-margin">Ведение дела</strong>&nbsp;в суде общей юрисдикции, включая изучение документов, консультирование, выработку позиции, составление искового заявления, отзыва, апелляционной или кассационной жалобы, представление интересов на всех судебных стадиях и в судебных инстанциях, помощь при исполнительном производстве.</p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style"><strong className="no-margin">от 50 000 рублей</strong></p>
                    <p className="p-style2"><span className="text-decoration-underline no-margin">доп. гонорар успеха</span></p>
                    <p className="p-style2">возможно взыскание суммы с другой стороны</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="547">
                    <p className="p-style2"><strong className="no-margin"><em className="no-margin">Оказание иной юридической помощи</em></strong></p>
                  </td>
                  <td className="table-cell" valign="top" width="187">
                    <p className="p-style2"><strong className="no-margin">по договору</strong></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Для организаций
          </Accordion.Header>
          <Accordion.Body>
            <table className="table-main" border="1" width="100%" cellSpacing="0" cellPadding="5">
              <tbody className="no-margin">
                <tr className="table-cell">
                  <td className="table-cell" valign="top">
                    <p className="p-style"><strong className="no-margin">Виды оказываемой юридической помощи</strong></p>
                  </td>
                  <td className="table-cell" valign="top" width="150">
                    <p className="p-style"><strong className="no-margin">Размер оплаты</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2"><strong className="no-margin">Юридическая справка</strong>&nbsp;(не более одного вопроса без консультирования)</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style"><strong className="no-margin">500 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2"><strong className="no-margin">Устная консультация</strong></p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style"><strong className="no-margin">от 1 000 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Сложная устная консультация, требующая изучения документов или поиска применимых норм права и путей решения</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">от 2 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Устное консультирование по вопросам уголовного, уголовно – процессуального и гражданского процессуального права, арбитражного и административного производства.</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">от 3 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2"><strong className="no-margin">Письменная консультация</strong>&nbsp;(с ссылками на нормы права или примерами из судебной практики)</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style"><strong className="no-margin">от 3 000 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Участие в переговорах (сопровождение адвоката)</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">по договору</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">&nbsp;</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style2">&nbsp;</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Составление небольшого по объёму документа</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">от 1 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Составление претензии, отзыва</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">от 3 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Составление правового заключения</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">от 5 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2"><strong className="no-margin">Составление проекта</strong>&nbsp;договора, документа внутреннего делопроизводства, учредительных документов, протоколов и т.д.</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style"><strong className="no-margin">от 5 000 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Составление отзыва в Арбитражный суд, суд, административный орган или орган власти</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">от 10 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2"><strong className="no-margin">Составление искового заявления</strong>, жалобы в Арбитражный суд, суд общей юрисдикции, адм. орган или орган власти&nbsp;<em className="no-margin fw-bold">(включая консультирование и изучение документов).</em></p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style"><strong className="no-margin">от 15 000 рублей</strong></p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2">Однократное участие в судебном заседании Арбитражного суда, суда общей юрисдикции, административном органе.</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style">от 20 000 рублей</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2"><strong className="no-margin">Ведение дела</strong>&nbsp;в Арбитражном суде, суде общей юрисдикции, включая изучение документов, консультирование, выработку позиции, составление искового заявления, отзыва, апелляционной или кассационной жалобы, представление интересов на всех судебных стадиях и в судебных инстанциях, помощь при исполнительном производстве.</p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style"><strong className="no-margin">от 50 000 рублей</strong></p>
                    <p className="p-style2"><span className="no-margin text-decoration-underline">доп. гонорар успеха</span></p>
                    <p className="p-style2">возможно взыскание суммы с другой cтороны</p>
                  </td>
                </tr>
                <tr className="table-cell">
                  <td className="table-cell" valign="top" width="508">
                    <p className="p-style2"><em className="no-margin fw-bold">Оказание иной юридической помощи</em></p>
                  </td>
                  <td className="table-cell" valign="top" width="170">
                    <p className="p-style"><strong className="no-margin">по договору</strong></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Layout>
  )
}

export default PricePage
